import React, { useEffect, useState } from 'react';
import {
    Button,
    Flex,
    Grid,
    GridItem,
    Modal,
    Text
} from '@bigcommerce/big-design';
import ConnectifStoreService from '../../services/ConnectifStoreService';
import { ConnectifStore } from '../../models/ConnectifStore';
import { AppSettings } from '../../models/AppSettings';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../components/Spinner';
import { useAlerts } from '../../state/AlertsContext';
import { useSignedPayload } from '../../state/SignedPayloadContext';
import { StoreError } from '../../models/AppSettingsResponse';
import { CatalogSettings } from '../../components/CatalogSettings/CatalogSettings';
import { StoreSettingsBox } from '../../components/StoreSettingsBox/StoreSettingsBox';
import { ErrorCode, getErrorMessage } from '../../utils/errorMessage';
import { ScriptConsentCategory } from '../../enums/ScriptConsentCategory';
import { ConnectifServiceWorkerAction } from '../../enums/ConnectifServiceWorkerAction';

export const initAppSettings: AppSettings = {
    trackingEnabled: false,
    multiStoreEnabled: false,
    useSkuAsProductId: false,
    offlineShopping: false,
    offlineShoppingOrderStatuses: [],
    productImageProperties: {
        width: 640,
        height: 640
    },
    productThumbnailImageProperties: {
        width: 200,
        height: 200
    },
    clientId: '',
    clientSecret: '',
    scriptConsentCategory: ScriptConsentCategory.Essential
};

const HomePage = () => {
    const { t } = useTranslation();
    const [appSettings, setAppSettings] =
        useState<AppSettings>(initAppSettings);
    const [defaultConnectifStore, setDefaultConnectifStore] =
        useState<ConnectifStore>({
            clientId: '',
            clientSecret: ''
        });
    const [multiStoreErrors, setMultiStoreErrors] = useState<StoreError[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { addAlert } = useAlerts();
    const { signedPayload } = useSignedPayload();
    const [isSaveSettingsEnabled, setIsSaveSettingsEnabled] = useState(true);
    const [trackingEnabled, setTrackingEnabled] = useState(
        initAppSettings.trackingEnabled
    );
    const [
        isOpenServiceWorkerPageWarningModal,
        setIsOpenServiceWorkerPageWarningModal
    ] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        loadSettings();
        setIsLoading(false);
    }, [signedPayload]);

    const loadSettings = async () => {
        if (!signedPayload) {
            return;
        }
        const appSettingsGetResult =
            await ConnectifStoreService.getSettings(signedPayload);
        if (appSettingsGetResult.success) {
            setAppSettings(appSettingsGetResult.appSettings);
            setDefaultConnectifStore({
                clientId: appSettingsGetResult.appSettings.clientId || '',
                clientSecret:
                    appSettingsGetResult.appSettings.clientSecret || ''
            });
            setTrackingEnabled(
                appSettingsGetResult.appSettings.trackingEnabled
            );
        }
    };

    useEffect(() => {
        if (
            appSettings &&
            !appSettings.multiStoreEnabled &&
            appSettings.clientId &&
            appSettings.clientSecret
        ) {
            setDefaultConnectifStore({
                clientId: appSettings.clientId,
                clientSecret: appSettings.clientSecret
            });
        }
    }, [appSettings]);

    const handleSwitchTracking = (trackingStatus: boolean) => {
        setTrackingEnabled(trackingStatus);
    };

    const handleSwitchMultiStoreSettings = (status: boolean) => {
        if (appSettings) {
            setAppSettings({ ...appSettings, multiStoreEnabled: status });
        }
    };

    const onChangeDefaultConnectifStore = (
        connectifStorePayload: ConnectifStore,
        hasErrors: boolean
    ) => {
        if (hasErrors && appSettings.trackingEnabled) {
            setIsSaveSettingsEnabled(false);
        }
        setDefaultConnectifStore(connectifStorePayload);
    };

    const handleSaveConfigButton = async () => {
        await saveConfig();
    };

    const handleServiceWorkerWarningModal = async (
        connectifServiceWorkerAction: ConnectifServiceWorkerAction
    ) => {
        await saveConfig(connectifServiceWorkerAction);
        setIsOpenServiceWorkerPageWarningModal(false);
    };

    const saveConfig = async (
        connectifServiceWorkerAction?: ConnectifServiceWorkerAction
    ) => {
        setIsLoading(true);
        try {
            const result = await ConnectifStoreService.saveSettings(
                signedPayload,
                {
                    ...appSettings,
                    clientId: defaultConnectifStore.clientId,
                    clientSecret: defaultConnectifStore.clientSecret,
                    trackingEnabled,
                    connectifServiceWorkerAction: connectifServiceWorkerAction
                        ? connectifServiceWorkerAction
                        : appSettings.connectifServiceWorkerAction
                }
            );

            if (result.success === true) {
                setAppSettings(result.appSettings);
                addAlert('success', t('MESSAGE.SETTINGS_SAVED'));
                await loadSettings();
            } else {
                if (result.error) {
                    switch (result.error.errorCode) {
                        case ErrorCode.E0101: {
                            const { clientId, currencyCode } = result.error;
                            if (
                                clientId &&
                                currencyCode &&
                                appSettings.multiStoreEnabled
                            ) {
                                setMultiStoreErrors([
                                    {
                                        clientId,
                                        currencyCode,
                                        errorCode: result.error.errorCode
                                    }
                                ]);
                            } else {
                                addAlert(
                                    'error',
                                    getErrorMessage(ErrorCode.E0101, t)
                                );
                            }

                            break;
                        }
                        case ErrorCode.E0201: {
                            addAlert(
                                'error',
                                getErrorMessage(ErrorCode.E0201, t)
                            );
                            break;
                        }
                        case ErrorCode.E0202: {
                            addAlert(
                                'error',
                                getErrorMessage(ErrorCode.E0203, t)
                            );
                            break;
                        }
                        case ErrorCode.E0203: {
                            addAlert(
                                'error',
                                getErrorMessage(ErrorCode.E0203, t)
                            );
                            break;
                        }
                        case ErrorCode.E0301: {
                            setIsOpenServiceWorkerPageWarningModal(true);
                            break;
                        }
                        default: {
                            addAlert('error', t('MESSAGE.GENERAL_ERROR'));
                        }
                    }
                } else {
                    addAlert('error', t('MESSAGE.GENERAL_ERROR'));
                }
            }
        } catch (error) {
            addAlert('error', t('MESSAGE.GENERAL_ERROR'));
        } finally {
            setIsLoading(false);
        }
    };

    const onChangeAppSettings = (appSettingsData: AppSettings) => {
        if (
            appSettingsData.offlineShopping &&
            appSettingsData.offlineShoppingOrderStatuses.length === 0
        ) {
            setIsSaveSettingsEnabled(false);
        } else {
            setIsSaveSettingsEnabled(true);
        }
        setAppSettings(appSettingsData);
    };

    return signedPayload ? (
        <>
            <Grid margin={'xxLarge'}>
                <GridItem>
                    <CatalogSettings
                        appSettings={appSettings}
                        onChangeAppSettings={onChangeAppSettings}
                    />
                </GridItem>
                <GridItem>
                    <StoreSettingsBox
                        appSettings={appSettings}
                        defaultConnectifStore={defaultConnectifStore}
                        onChangeTrackingStatus={handleSwitchTracking}
                        handleSwitchMultiStoreSettings={
                            handleSwitchMultiStoreSettings
                        }
                        onChangeDefaultConnectifStore={
                            onChangeDefaultConnectifStore
                        }
                        multiStoreErrors={multiStoreErrors}
                    />
                </GridItem>
                <GridItem marginTop={'small'}>
                    <Button
                        type='button'
                        onClick={handleSaveConfigButton}
                        variant='primary'
                        disabled={!isSaveSettingsEnabled}
                    >
                        {t('SAVE_CONFIG')}
                    </Button>
                </GridItem>
            </Grid>
            <Spinner visible={isLoading} />
            <Modal
                actions={[
                    {
                        text: t('SERVICE_WORKING_WARNING_MODAL.CANCEL_BUTTON'),
                        variant: 'subtle',
                        onClick: () =>
                            setIsOpenServiceWorkerPageWarningModal(false)
                    },
                    {
                        text: t(
                            'SERVICE_WORKING_WARNING_MODAL.MAINTAIN_PAGE_BUTTON'
                        ),
                        variant: 'secondary',
                        onClick: () =>
                            handleServiceWorkerWarningModal(
                                ConnectifServiceWorkerAction.DoNothing
                            )
                    },
                    {
                        text: t(
                            'SERVICE_WORKING_WARNING_MODAL.REPLACE_PAGE_BUTTON'
                        ),
                        onClick: () =>
                            handleServiceWorkerWarningModal(
                                ConnectifServiceWorkerAction.Overwrite
                            )
                    }
                ]}
                closeOnClickOutside={false}
                closeOnEscKey={true}
                header={t('SERVICE_WORKING_WARNING_MODAL.TITLE')}
                isOpen={isOpenServiceWorkerPageWarningModal}
                onClose={() => setIsOpenServiceWorkerPageWarningModal(false)}
            >
                <Text>{t('SERVICE_WORKING_WARNING_MODAL.DESCRIPTION')}</Text>
            </Modal>
        </>
    ) : (
        <Flex justifyContent={'center'}>
            <h3>{t('MESSAGE.APP_INSTANCE_ERROR')}</h3>
        </Flex>
    );
};
export default HomePage;
